var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-general-info-3 animate__animated animate__fadeIn pt-4 pb-5 mb-auto"},[_c('img',{staticClass:"w-100 mb-4",staticStyle:{"display":"none"},attrs:{"src":require('@/assets/banners/update-pensions-charities-gyms.png'),"alt":""},on:{"load":(e) => {
        e.target.style.display = 'unset';
        _vm.hideSpinner(`banner`);
      }}}),_vm._m(0),(_vm.informPension)?_c('div',[_c('h5',{staticClass:"fs-la mb-3"},[_vm._v("Which pension provider(s) would you like to update?")]),_c('p',{staticClass:"fs-sm"},[_vm._v("Select as many providers as you need")]),_c('div',{staticClass:"input-search-container mb-3"},[_vm._m(1),_c('vSelect',{ref:"vSelect",staticClass:"w-100 fs-md",attrs:{"label":"name","clearable":false,"options":_vm.pensions,"placeholder":`Search pension, e.g.'Aviva'`,"multiple":false},on:{"option:selected":(ev) => _vm.selectChange(ev, 'pensionSelection'),"search":_vm.onSearch},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex align-items-center py-1"},[_c('img',{staticClass:"me-3",staticStyle:{"display":"none"},attrs:{"src":`${_vm.urlBucket}/${option.icon}`,"width":"30px"},on:{"load":(e) => {
                  e.target.style.display = 'unset';
                  _vm.hideSpinner(option.name);
                }}}),_c('div',{staticClass:"text-center me-3",attrs:{"id":option.name}},[_c('img',{attrs:{"src":"/assets/img/spinner-loading.gif","width":"30px","alt":""}})]),_c('p',{staticClass:"mb-0 fs-md"},[_vm._v(_vm._s(option.name))])])]}},{key:"list-footer",fn:function(){return [_c('div',{staticClass:"text-center py-2 c-pointer",on:{"click":function($event){return _vm.showSuggestedSection()}}},[_c('a',{staticClass:"mb-1 text-black fw-normal"},[_vm._v("Can't find the company you're looking for? 🤔")])])]},proxy:true}],null,false,3860931398)},[_c('template',{slot:"no-options"},[_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"mb-1"},[_vm._v("Oops, it looks like there are no matching options.")])])])],2)],1),(_vm.errors.pensions)?_c('p',{staticStyle:{"color":"#c64a45"}},[_vm._v("* This field is required")]):_vm._e(),_c('div',{staticClass:"chip-list-wrapper mb-4"},_vm._l((_vm.pensionSelection),function(item,index){return _c('div',{key:index,staticClass:"chip me-1 mb-2",on:{"click":function($event){return _vm.deleteService(index, 'pensionSelection')}}},[_c('i',{staticClass:"fa-sharp fa-solid fa-circle-check"}),_c('p',{staticClass:"ms-1 mb-0"},[_vm._v(_vm._s(item.name))])])}),0)]):_vm._e(),(_vm.informCharities)?_c('div',[_c('h5',{staticClass:"fs-la mb-3"},[_vm._v("Which charity(s) would you like to update?")]),_c('div',{staticClass:"input-search-container mb-3"},[_vm._m(2),_c('vSelect',{ref:"vSelect2",staticClass:"w-100 fs-md",attrs:{"label":"name","clearable":false,"options":_vm.charities,"placeholder":`Search Charities`,"multiple":false},on:{"option:selected":(ev) => _vm.selectChange(ev, 'selectedCharities')},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex align-items-center py-1"},[_c('img',{staticClass:"me-3",staticStyle:{"display":"none"},attrs:{"src":`${_vm.urlBucket}/${option.icon}`,"width":"30px"},on:{"load":(e) => {
                  e.target.style.display = 'unset';
                  _vm.hideSpinner(option.name);
                }}}),_c('div',{staticClass:"text-center me-3",attrs:{"id":option.name}},[_c('img',{attrs:{"src":"/assets/img/spinner-loading.gif","width":"30px","alt":""}})]),_c('p',{staticClass:"mb-0 fs-md"},[_vm._v(_vm._s(option.name))])])]}},{key:"list-footer",fn:function(){return [_c('div',{staticClass:"text-center py-2 c-pointer",on:{"click":function($event){return _vm.showSuggestedSection()}}},[_c('a',{staticClass:"mb-1 text-black fw-normal"},[_vm._v("Can't find the company you're looking for? 🤔")])])]},proxy:true}],null,false,3860931398)},[_c('template',{slot:"no-options"},[_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"mb-1"},[_vm._v("Oops, it looks like there are no matching options.")])])])],2)],1),(_vm.errors.charities)?_c('p',{staticStyle:{"color":"#c64a45"}},[_vm._v("* This field is required")]):_vm._e(),_c('div',{staticClass:"chip-list-wrapper mb-4"},_vm._l((_vm.selectedCharities),function(item,index){return _c('div',{key:index,staticClass:"chip me-1 mb-2",on:{"click":function($event){return _vm.deleteService(index, 'selectedCharities')}}},[_c('i',{staticClass:"fa-sharp fa-solid fa-circle-check"}),_c('p',{staticClass:"ms-1 mb-0"},[_vm._v(_vm._s(item.name))])])}),0)]):_vm._e(),(_vm.informHomecare)?_c('div',[_c('h5',{staticClass:"fs-la mb-3"},[_vm._v("Which Home Care would you like to update?")]),_c('div',{staticClass:"input-search-container mb-3"},[_vm._m(3),_c('vSelect',{ref:"vSelect3",staticClass:"w-100 fs-md",attrs:{"label":"name","clearable":false,"options":_vm.homecare,"placeholder":`Search Homecare`,"multiple":false},on:{"option:selected":(ev) => _vm.selectChange(ev, 'selectedHomecare')},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex align-items-center py-1"},[_c('img',{staticClass:"me-3",staticStyle:{"display":"none"},attrs:{"src":`${_vm.urlBucket}/${option.icon}`,"width":"30px"},on:{"load":(e) => {
                  e.target.style.display = 'unset';
                  _vm.hideSpinner(option.name);
                }}}),_c('div',{staticClass:"text-center me-3",attrs:{"id":option.name}},[_c('img',{attrs:{"src":"/assets/img/spinner-loading.gif","width":"30px","alt":""}})]),_c('p',{staticClass:"mb-0 fs-md"},[_vm._v(_vm._s(option.name))])])]}},{key:"list-footer",fn:function(){return [_c('div',{staticClass:"text-center py-2 c-pointer",on:{"click":function($event){return _vm.showSuggestedSection()}}},[_c('a',{staticClass:"mb-1 text-black fw-normal"},[_vm._v("Can't find the company you're looking for? 🤔")])])]},proxy:true}],null,false,3860931398)},[_c('template',{slot:"no-options"},[_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"mb-1"},[_vm._v("Oops, it looks like there are no matching options.")])])])],2)],1),(_vm.errors.homecare)?_c('p',{staticStyle:{"color":"#c64a45"}},[_vm._v("* This field is required")]):_vm._e(),_c('div',{staticClass:"chip-list-wrapper mb-4"},_vm._l((_vm.selectedHomecare),function(item,index){return _c('div',{key:index,staticClass:"chip me-1 mb-2",on:{"click":function($event){return _vm.deleteService(index, 'selectedHomecare')}}},[_c('i',{staticClass:"fa-sharp fa-solid fa-circle-check"}),_c('p',{staticClass:"ms-1 mb-0"},[_vm._v(_vm._s(item.name))])])}),0)]):_vm._e(),(_vm.informBreakdown)?_c('div',[_c('h5',{staticClass:"fs-la mb-3"},[_vm._v("Which Breakdown would you like to update?")]),_c('div',{staticClass:"input-search-container mb-3"},[_vm._m(4),_c('vSelect',{ref:"vSelect4",staticClass:"w-100 fs-md",attrs:{"label":"name","clearable":false,"options":_vm.breakdownCompanies,"placeholder":`Search Breakdown`,"multiple":false},on:{"option:selected":(ev) => _vm.selectChange(ev, 'selectedBreakdown')},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex align-items-center py-1"},[_c('img',{staticClass:"me-3",staticStyle:{"display":"none"},attrs:{"src":`${_vm.urlBucket}/${option.icon}`,"width":"30px"},on:{"load":(e) => {
                  e.target.style.display = 'unset';
                  _vm.hideSpinner(option.name);
                }}}),_c('div',{staticClass:"text-center me-3",attrs:{"id":option.name}},[_c('img',{attrs:{"src":"/assets/img/spinner-loading.gif","width":"30px","alt":""}})]),_c('p',{staticClass:"mb-0 fs-md"},[_vm._v(_vm._s(option.name))])])]}},{key:"list-footer",fn:function(){return [_c('div',{staticClass:"text-center py-2 c-pointer",on:{"click":function($event){return _vm.showSuggestedSection()}}},[_c('a',{staticClass:"mb-1 text-black fw-normal"},[_vm._v("Can't find the company you're looking for? 🤔")])])]},proxy:true}],null,false,3860931398)},[_c('template',{slot:"no-options"},[_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"mb-1"},[_vm._v("Oops, it looks like there are no matching options.")])])])],2)],1),(_vm.errors.breakdown)?_c('p',{staticStyle:{"color":"#c64a45"}},[_vm._v("* This field is required")]):_vm._e(),_c('div',{staticClass:"chip-list-wrapper mb-4"},_vm._l((_vm.selectedBreakdown),function(item,index){return _c('div',{key:index,staticClass:"chip me-1 mb-2",on:{"click":function($event){return _vm.deleteService(index, 'selectedBreakdown')}}},[_c('i',{staticClass:"fa-sharp fa-solid fa-circle-check"}),_c('p',{staticClass:"ms-1 mb-0"},[_vm._v(_vm._s(item.name))])])}),0)]):_vm._e(),(_vm.informGyms)?_c('div',[_c('h5',{staticClass:"fs-la"},[_vm._v("Which gym(s) would you like to update?")]),_c('div',{staticClass:"input-search-container mb-3"},[_vm._m(5),_c('vSelect',{ref:"vSelect5",staticClass:"w-100 fs-md",attrs:{"label":"name","clearable":false,"options":_vm.gyms,"placeholder":`Search Gyms`,"multiple":false},on:{"option:selected":(ev) => _vm.selectChange(ev, 'selectedGyms')},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex align-items-center py-1"},[_c('img',{staticClass:"me-3",staticStyle:{"display":"none"},attrs:{"src":`${_vm.urlBucket}/${option.icon}`,"width":"30px"},on:{"load":(e) => {
                  e.target.style.display = 'unset';
                  _vm.hideSpinner(option.name);
                }}}),_c('div',{staticClass:"text-center me-3",attrs:{"id":option.name}},[_c('img',{attrs:{"src":"/assets/img/spinner-loading.gif","width":"30px","alt":""}})]),_c('p',{staticClass:"mb-0 fs-md"},[_vm._v(_vm._s(option.name))])])]}},{key:"list-footer",fn:function(){return [_c('div',{staticClass:"text-center py-2 c-pointer",on:{"click":function($event){return _vm.showSuggestedSection()}}},[_c('a',{staticClass:"mb-1 text-black fw-normal"},[_vm._v("Can't find the company you're looking for? 🤔")])])]},proxy:true}],null,false,3860931398)},[_c('template',{slot:"no-options"},[_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"mb-1"},[_vm._v("Oops, it looks like there are no matching options.")])])])],2)],1),(_vm.errors.gyms)?_c('p',{staticStyle:{"color":"#c64a45"}},[_vm._v("* This field is required")]):_vm._e(),_c('div',{staticClass:"chip-list-wrapper"},_vm._l((_vm.selectedGyms),function(item,index){return _c('div',{key:index,staticClass:"chip me-1 mb-2",on:{"click":function($event){return _vm.deleteService(index, 'selectedGyms')}}},[_c('i',{staticClass:"fa-sharp fa-solid fa-circle-check"}),_c('p',{staticClass:"ms-1 mb-0"},[_vm._v(_vm._s(item.name))])])}),0)]):_vm._e(),(_vm.suggestedSection)?_c('div',{staticClass:"suggested-companies mt-3"},[_c('SuggestedCompany',{on:{"inputValue":(e)=> _vm.suggestedCompany = e}})],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center my-5",attrs:{"id":"banner"}},[_c('img',{attrs:{"src":"/assets/img/spinner-loading.gif","width":"100px","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-search-icon"},[_c('i',{staticClass:"fa-solid fa-magnifying-glass"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-search-icon"},[_c('i',{staticClass:"fa-solid fa-magnifying-glass"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-search-icon"},[_c('i',{staticClass:"fa-solid fa-magnifying-glass"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-search-icon"},[_c('i',{staticClass:"fa-solid fa-magnifying-glass"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-search-icon"},[_c('i',{staticClass:"fa-solid fa-magnifying-glass"})])
}]

export { render, staticRenderFns }